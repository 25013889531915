import React, {Component} from 'react'
import {Formik} from 'formik'
import axios from 'axios'
import FormMessages from '../FormMessages'
import InnerForm from './InnerForm'
import {object, string} from 'yup'

function encode(data) {
    return Object.keys(data)
        .map(
            key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join('&')
}

class ContactForm extends Component {
    state = {
        submit: null,
    }

    resetForm = () => {
        this.setState({submit: null})
    }

    handleSubmit = data => {
        /* eslint-disable-next-line */
        const {recaptcha, gotcha, ...rest} = data;

        axios({
            method: 'post',
            url: 'https://formcarry.com/s/r1ncILmZ7',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: encode({...rest}),
        })
            .then(() => {
                this.setState({submit: 'success'})
            })
            .catch(error => {
                this.setState({submit: 'fail'})
                throw new Error(error)
            })
    }

    render() {
        const schema = object().shape({
            name: string().required('required'),
            email: string()
                .email('invalid email address')
                .required('required'),
            message: string().required(''),
            recaptcha: string().required('you must perform the robot test'),
        })

        return (
            <div>
                {this.state.submit !== null ? (
                    <FormMessages
                        status={this.state.submit}
                        handleClick={this.resetForm}
                    />
                ) : (
                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            message: '',
                            gotcha: '',
                        }}
                        onSubmit={this.handleSubmit}
                        validationSchema={schema}
                    >{InnerForm}</Formik>
                )}
            </div>
        )
    }
}

export default ContactForm
