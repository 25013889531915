/** @jsx jsx */
import React from 'react';
import {jsx, css} from '@emotion/core';
import vars from '../../styles/vars';
import {FormCombinedRow, FormGroup, formControl, button} from '../../styles';
import {Form, Field} from 'formik';
import Recaptcha from 'react-google-recaptcha';

const ErrorMessage = (props) => {
    const {touched, errors} = props;
    if (touched && errors) {
        return (
            <div
                css={css`
                    color: ${vars.red};
                    font-size: 1.3rem;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                `}
            >
                {errors}
            </div>
        );
    }
    return null;
};

const ContactFormInner = (props) => {
    const {
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        setFieldValue,
        values,
    } = props;

    const RECAPTCHA_KEY = process.env.GATSBY_RECAPTCHA_KEY;
    const recaptchaRef = React.useRef("recaptcha");

    const getClassNames = (touch, error) => {
        if (touch && error) {
            return 'has-error';
        }
        return '';
    };

    return (
        <Form method="POST" name="contact" acceptCharset="UTF-8">
            <Field type="hidden" name="form-name" />
            <FormCombinedRow>
                <FormGroup className="form-group--two">
                    <label htmlFor="contactName">{'Name'}</label>
                    <Field
                        css={[
                            formControl,
                            getClassNames(touched.name, errors.name),
                        ]}
                        id="contactName"
                        autoFocus
                        type="text"
                        name="name"
                    />
                    <ErrorMessage touched={touched.name} errors={errors.name} />
                </FormGroup>
                <FormGroup className="form-group--two">
                    <label htmlFor="contactEmail">{'Email'}</label>
                    <Field
                        css={[
                            formControl,
                            getClassNames(touched.email, errors.email),
                        ]}
                        id="contactEmail"
                        type="email"
                        name="email"
                    />

                    <ErrorMessage
                        touched={touched.email}
                        errors={errors.email}
                    />
                </FormGroup>
            </FormCombinedRow>
            <FormGroup>
                <label htmlFor="message">{'Message'}</label>
                <Field
                    css={[
                        formControl,
                        getClassNames(touched.message, errors.message),
                    ]}
                    placeholder="Dear Emma Goldman . . ."
                    id="message"
                    as="textarea"
                    name="message"
                />
            </FormGroup>
            <div css={{display: 'none'}}>
                <label htmlFor="gotcha">
                    {"leave blank if you're a human"}
                </label>
                <Field name="gotcha" type="text" />
            </div>
            {touched.email && touched.name ? (
                <div css={{marginBottom: '2rem'}}>
                    <Recaptcha
                        ref={recaptchaRef}
                        sitekey={RECAPTCHA_KEY}
                        onChange={(response) => {
                            setFieldValue('recaptcha', response);
                        }}
                        theme="dark"
                    />
                </div>
            ) : (
                ''
            )}
            <button css={button} type="submit" disabled={isSubmitting}>
                Submit
            </button>
        </Form>
    );
};

export default ContactFormInner;
